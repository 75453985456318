import debounce from "lodash.debounce";
import { createRoot } from "react-dom/client";
import App from "../../map/app";
import state from "../../map/state";

document.addEventListener("DOMContentLoaded", () => {
  const container = document.getElementById("leaflet-map") as HTMLElement;
  const root = createRoot(container);
  const className = container.getAttribute("data-className");
  root.render(<App className={className ?? ""} />);

  document.querySelectorAll("form input[name=street]").forEach((input) => {
    input.addEventListener(
      "keydown",
      debounce((e: any) => {
        const target = e.target as HTMLInputElement;

        if (e.key === "Enter" || !target.value) {
          e.preventDefault();
          e.stopPropagation();
          state.street = target.value;
          return false;
        }
      }, 300)
    );
  });

  document.querySelectorAll("form input[name=street] + input[type=button]").forEach((button) => {
    button.addEventListener("click", (e) => {
      const input = (e.target as HTMLInputElement).previousElementSibling as HTMLInputElement;
      state.street = input.value;
    });
  });

  const form = document.querySelector("form[name=form]") as HTMLFormElement;

  document
    .querySelectorAll("form input[name=search_query] + input[type=button]")
    .forEach((button) => {
      button.previousElementSibling?.addEventListener("keydown", (e) => {
        if (e.key === "Enter") {
          e.preventDefault();
          e.stopPropagation();
          if (form) {
            form.submit();
          }
        }
      });

      button.addEventListener("click", (e) => {
        // find form and submit
        if (form) {
          form.submit();
        }
      });
    });
});
